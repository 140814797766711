import { AutocompleteItem, Button, MessageBox, makeStyles } from '@akelius-con/react-ui-kit-components';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { cacheKeys } from 'modules/order/constants';
import { Company } from 'modules/order/graphql/queries/getCompany';
import { Project, ProjectQueryResponse, getProject } from 'shared/graphql/queries/getProject';
import { Apartment, OrderTypesEnum, Property } from 'modules/order/types';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { StickyPageFooter } from 'shared/components';
import DESIGN_CONSTANT from 'shared/designConstant';
import * as yup from 'yup';
import { FormikTextField } from 'shared/components/FormikTextField';
import MandatoryFieldDisclaimer from 'shared/components/MandatoryFieldDisclaimer';
import { ProjectCard } from '../../../../components/ProjectCard';
import SelectAdminCompanyInfo from '../../../../components/SelectAdminCompanyInfo';
import SelectApartmentDropdown from '../../../../components/SelectApartmentDropdown';
import { SelectProjectDropdown } from 'shared/components/SelectProjectDropDown';
import SuppliersDropdown, { getSupplierOptionFromSupplier } from 'shared/components/SuppliersDropdown';
import AdminCostCenterCard from './AdminCostCenterCard';
import PropertyCard from './PropertyCard';
import SupplierCard from './SupplierCard';
import { FormInputs, HandleSubmitType } from './types';
import { Supplier } from 'shared/graphql/queries/getSuppliers';
import { useGetUser } from 'modules/order/utils/useGetUser';
import PurposeDropdown, { getPurposeOption } from 'shared/components/PurposeDropdown';

interface Props {
    handleOrderSubmit: HandleSubmitType;
    isLoading: boolean;
}

const useStyles = makeStyles()({
    actions: {
        marginTop: '20px',
    },
    wrapper: {
        maxWidth: DESIGN_CONSTANT.formContentWidth,
    },
    alignmentFix: {
        '& .MuiFormControl-root': {
            marginTop: 0,
        },
    },
});

export const getSelectedProjectOption = (projectRes?: ProjectQueryResponse): AutocompleteItem | null => {
    if (!projectRes) return null;
    const projectId = projectRes.project.projectId;

    return {
        label: projectId,
        value: projectId,
    };
};

const CreateOrderForm = (props: Props) => {
    const { handleOrderSubmit, isLoading } = props;
    const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);
    const [selectedApartment, setSelectedApartment] = useState<Apartment | null>(null);
    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
    const [selectedCostCenter, setSelectedCostCenter] = useState<AutocompleteItem | null>(null);
    const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(null);
    const [selectedPurpose, setSelectedPurpose] = useState<OrderTypesEnum>(OrderTypesEnum.PROPERTY);

    const { getToken } = useGetUser();
    const { t } = useTranslation();
    const { classes } = useStyles();

    const initialValues: FormInputs = {
        title: '',
        supplierId: '',
        propertyId: '',
        projectId: '',
        apartmentId: '',
        costCenterType: OrderTypesEnum.PROPERTY,
        companyId: '',
        costCenter: '',
    };

    const validationSchema = yup.object().shape({
        title: yup.string().required().max(100).label(t('purchase-order.title')),
        supplierId: yup.string().required().label(t('purchase-order.supplier')),
        costCenterType: yup.mixed().oneOf([OrderTypesEnum.PROPERTY, OrderTypesEnum.ADMIN, OrderTypesEnum.CONSTRUCTION]),
        propertyId: yup.string().when('costCenterType', {
            is: OrderTypesEnum.PROPERTY || OrderTypesEnum.CONSTRUCTION,
            then: () => yup.string().required().label(t('purchase-order.property')),
        }),
        apartmentId: yup.string().label(t('purchase-order.apartment')),
        companyId: yup.string().when('costCenterType', {
            is: OrderTypesEnum.ADMIN,
            then: () => yup.string().required().label(t('purchase-order.common.company')),
        }),
        projectId: yup.string().when('costCenterType', {
            is: OrderTypesEnum.CONSTRUCTION,
            then: () => yup.string().required().label(t('purchase-order.common.company')),
        }),
        costCenter: yup.string().when('costCenterType', {
            is: OrderTypesEnum.ADMIN,
            then: () => yup.string().required().label(t('purchase-order.common.cost-center')),
        }),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: true,
        onSubmit: handleOrderSubmit,
    });

    const { values, handleSubmit, setFieldValue } = formik;

    const supplierChangeHandler = useCallback(
        (supplier: Supplier | null) => {
            setFieldValue('supplierId', supplier?.id);
            setSelectedSupplier(supplier);
        },
        [setFieldValue],
    );

    const purposeChangeHandler = useCallback(
        (purpose: AutocompleteItem) => {
            setFieldValue('costCenterType', purpose.value);
            setSelectedPurpose(purpose.value as OrderTypesEnum);
        },
        [setFieldValue],
    );

    const {
        error: projectFetchError,
        data: projectDetailsInfo,
        refetch: fetchProjectInfo,
    } = useQuery(cacheKeys.project(values.projectId), getProject({ getToken, projectId: values.projectId || null }), {
        retry: false,
        enabled: false,
    });

    const isAdminCostCenter = formik.values.costCenterType === OrderTypesEnum.ADMIN;
    const isConstructionCostCenter = formik.values.costCenterType === OrderTypesEnum.CONSTRUCTION;
    const isPropertyCostCenter = formik.values.costCenterType === OrderTypesEnum.PROPERTY;

    return (
        <form onSubmit={handleSubmit}>
            <div className={classes.wrapper}>
                <Grid container spacing={2} mb={6}>
                    <>
                        {projectFetchError && (
                            <Grid item xs={12}>
                                <MessageBox type="error">{t('purchase-order.project.fetch-error')}</MessageBox>
                            </Grid>
                        )}
                        <Grid item xs={4} mt={3}>
                            <FormikTextField required data-testid="order-title" name="title" formik={formik} label={t('purchase-order.title')} />
                        </Grid>
                        <Grid item xs={4} className={classes.alignmentFix} mt={3}>
                            <SuppliersDropdown
                                required
                                label={t('purchase-order.supplier')}
                                value={getSupplierOptionFromSupplier(selectedSupplier)}
                                formik={formik}
                                onChange={supplierChangeHandler}
                            />
                        </Grid>
                        <Grid item xs={4} className={classes.alignmentFix} mt={3}>
                            <PurposeDropdown
                                required
                                label={t('purchase-order.purpose')}
                                value={getPurposeOption(selectedPurpose)}
                                formik={formik}
                                onChange={purposeChangeHandler}
                            />
                        </Grid>

                        {isConstructionCostCenter && (
                            <>
                                <Grid item xs={6} mt={2}>
                                    <SelectProjectDropdown
                                        required
                                        label={t('purchase-order.construction-order.project')}
                                        selectedProject={getSelectedProjectOption(projectDetailsInfo)}
                                        formik={formik}
                                        onValueChange={(project: Project | null) => {
                                            setFieldValue('projectId', project?.projectId);
                                            setTimeout(function () {
                                                if (project) fetchProjectInfo();
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} />
                                <Grid item xs={6}>
                                    {projectDetailsInfo?.project && values.projectId && (
                                        <ProjectCard
                                            hideEdit
                                            disabled
                                            profitCenter={projectDetailsInfo.project.costCenter}
                                            property={projectDetailsInfo.project.property}
                                            project={{
                                                ...projectDetailsInfo.project,
                                                id: projectDetailsInfo.project.projectId,
                                            }}
                                        />
                                    )}
                                </Grid>
                            </>
                        )}

                        {isPropertyCostCenter && (
                            <>
                                <Grid item xs={12} mt={2}>
                                    <SelectApartmentDropdown
                                        selectedProperty={selectedProperty}
                                        apartments={selectedProperty?.apartments || []}
                                        selectedApartment={selectedApartment}
                                        formik={formik}
                                        onPropertyChange={(property: Property | null) => {
                                            setSelectedProperty(property);
                                            setFieldValue('propertyId', property?.id || '');
                                        }}
                                        onApartmentChange={(apartment: Apartment | null) => {
                                            setFieldValue('apartmentId', apartment?.id || '');
                                            setSelectedApartment(apartment);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {selectedProperty && (
                                        <PropertyCard
                                            property={selectedProperty}
                                            apartment={selectedApartment}
                                            profitCenter={selectedProperty.costCenter}
                                        />
                                    )}
                                </Grid>
                            </>
                        )}

                        {isAdminCostCenter && (
                            <>
                                <Grid item xs={12} mt={2}>
                                    <SelectAdminCompanyInfo
                                        required
                                        formik={formik}
                                        selectedCompany={selectedCompany}
                                        selectedCostCenter={selectedCostCenter}
                                        onCompanyChange={(company: Company | null) => {
                                            setTimeout(() => {
                                                setSelectedCompany(company || null);
                                                setFieldValue('companyId', company?.id || '');
                                            });
                                        }}
                                        onCostCenterChange={(costCenter: AutocompleteItem | null) => {
                                            setTimeout(() => {
                                                setSelectedCostCenter(costCenter || null);
                                                setFieldValue('costCenter', costCenter?.value || '');
                                            });
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    {selectedCompany && <AdminCostCenterCard company={selectedCompany} costCenterId={values.costCenter} />}
                                </Grid>
                            </>
                        )}

                        <Grid item xs={6}>
                            <>{values.supplierId && selectedSupplier && <SupplierCard supplier={selectedSupplier} />}</>
                        </Grid>
                        <Grid item xs={12}>
                            <MandatoryFieldDisclaimer />
                        </Grid>
                    </>
                </Grid>
                <StickyPageFooter>
                    <Grid container spacing={3} justifyContent="space-between">
                        <Grid item />
                        <Grid item>
                            <Button
                                isLoading={isLoading}
                                type="submit"
                                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                                variant="contained"
                                label={t('common.create')}
                                data-testid="create-button"
                            />
                        </Grid>
                    </Grid>
                </StickyPageFooter>
            </div>
        </form>
    );
};

export default CreateOrderForm;
