import { colors } from '@akelius-con/react-theme';
import { Autocomplete, AutocompleteItem as Option, makeStyles } from '@akelius-con/react-ui-kit-components';
import { OnChange, OnInputChange } from '@akelius-con/react-ui-kit-components/Autocomplete';
import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FormikProps } from 'formik';
import debounce from 'lodash.debounce';
import { cacheKeys } from 'modules/order/constants';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEBOUNCE_INTERVAL } from 'shared/constant';
import { Supplier, SuppliersQueryResponse, getSuppliers } from 'shared/graphql/queries/getSuppliers';
import { getSelectedCountry } from 'shared/utils/serviceCountry';

interface Props {
    disabled?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik?: FormikProps<any>;
    value?: Option | null;
    required?: boolean;
    label: string;
    preventDisableOnSubmit?: boolean;
    onChange: (supplier: Supplier | null) => void;
}

const useStyles = makeStyles()({
    hide: {
        display: 'none',
    },
    error: {
        color: colors.error.main,
    },
});

export const getFullSupplier = (suppliers: Supplier[], supplierId: string): Supplier | null => {
    return suppliers.find(s => s.id === supplierId) || null;
};

export const getSupplierOptions = (suppliers: Supplier[]): Option[] => {
    return suppliers.map(s => ({
        label: s.name,
        value: s.id,
    }));
};

export const getSupplierOptionFromSupplier = (selectedSupplier: Supplier | null) => {
    return selectedSupplier ? { label: selectedSupplier?.name, value: selectedSupplier?.id } : null;
};

const SuppliersDropdown: FC<Props> = props => {
    const { onChange, value, formik, required, label, disabled, preventDisableOnSubmit } = props;
    const { t } = useTranslation();
    const { classes } = useStyles();
    const [searchStr, setSearchStr] = useState<string>('');
    const [suppliers, setSuppliers] = useState<Supplier[]>([]);
    const selectedCountry = getSelectedCountry();
    const { getToken } = useGetUser();

    const { isLoading, isError, error, refetch } = useQuery<SuppliersQueryResponse>(
        cacheKeys.suppliers(),
        getSuppliers({
            getToken,
            pageSize: 10,
            filter: {
                country: selectedCountry,
                name: searchStr,
            },
        }),
        {
            onSuccess: data => {
                setSuppliers(data.suppliers.items);
            },
            refetchOnWindowFocus: false,
        },
    );

    if (isError) {
        console.log('supplier query fetch error =', error);
    }

    const handleChangeSupplier: OnChange = (_event, selectedOption) => {
        if (selectedOption === null) {
            onChange(null);
            setSearchStr('');
            return;
        }

        if (formik && formik.values.supplierId === selectedOption.value) {
            onChange(null);
        } else {
            const fullSupplier = getFullSupplier(suppliers, selectedOption.value);
            onChange(fullSupplier);
        }
    };

    const debouncedRefetch = useMemo(() => debounce(() => refetch(), DEBOUNCE_INTERVAL), [refetch]);

    const onInputChange: OnInputChange = useCallback(
        (_event, value) => {
            setSearchStr(value.toLowerCase());

            if (!value.length) {
                setSuppliers([]);
            }
            debouncedRefetch();
        },
        [debouncedRefetch],
    );

    return (
        <>
            <Autocomplete
                onChange={handleChangeSupplier}
                options={getSupplierOptions(suppliers)}
                filterOptions={suppliers => suppliers}
                disabled={disabled || (!preventDisableOnSubmit && formik && formik.isSubmitting)}
                loading={isLoading}
                onInputChange={onInputChange}
                error={formik && !!(formik.errors.supplierId && formik.touched.supplierId)}
                loadingText={t('common.loading-suppliers')}
                onBlur={() => formik && formik.setFieldTouched('supplierId')}
                value={value || null}
                noOptionsText={t('common.search-for-suppliers')}
                data-testid="supplier-selection"
                inputProps={{
                    label: `${label} ${required ? '*' : ''}`,
                }}
            />

            {formik && formik.errors.supplierId && formik.touched.supplierId && (
                <Typography variant="body1" className={classes.error}>
                    {formik.errors.supplierId as string}
                </Typography>
            )}
        </>
    );
};

export default SuppliersDropdown;
