import { gql } from 'graphql-request';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';
import { ServiceCountriesEnum } from 'shared/types/ServicesCountriesEnum';

export interface SupplierContact {
    contactId: string;
    name: string;
    email: string;
    phone?: string;
    isSelected?: boolean;
}

export interface Supplier {
    id: string;
    name: string;
    address: string;
    addressDetails: {
        streetName?: string;
        streetNumber?: string;
        postalCode?: string;
        city?: string;
        region?: string;
        country: string;
    };
    contacts: SupplierContact[];
    additionalContacts?: SupplierContact[];
}

export interface SuppliersQueryResponse {
    suppliers: {
        items: Supplier[];
    };
}

interface GetSupplierParam {
    getToken: GetToken;
    pageSize: number;
    filter: {
        country: ServiceCountriesEnum;
        name: string;
    };
}

export const getSuppliers = (param: GetSupplierParam) => async () => {
    const { getToken, ...queryVars } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const getSuppliersQuery = gql`
        query suppliers($pageSize: Int!, $filter: SupplierFilter!) {
            suppliers(pageSize: $pageSize, filter: $filter) {
                items {
                    ... on Supplier {
                        id
                        name
                        addressDetails {
                            region
                            city
                            country
                        }
                    }
                }
            }
        }
    `;

    const data = await graphQLClient.request<SuppliersQueryResponse>(getSuppliersQuery, {
        ...queryVars,
    });

    return data;
};
