import { gql } from 'graphql-request';
import { GetToken } from 'modules/order/utils/useGetUser';
import { getGraphqlClient } from 'shared/graphql/useGraphQlClient';
import { Supplier } from './getSuppliers';

export interface SupplierQueryResponse {
    supplier: Supplier;
}

export interface GetSupplierParam {
    supplierId: string | null;
    getToken: GetToken;
}

export const getSupplier = (param: GetSupplierParam) => async () => {
    const { supplierId, getToken } = param;
    const { graphQLClient } = await getGraphqlClient(getToken);

    const getSupplierQuery = gql`
        query supplier($supplierId: String!) {
            supplier(supplierId: $supplierId) {
                id
                name
                address
                addressDetails {
                    region
                    city
                    country
                }
            }
        }
    `;

    const data = await graphQLClient.request<SupplierQueryResponse>(getSupplierQuery, {
        supplierId,
    });

    return data;
};
