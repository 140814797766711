import { colors } from '@akelius-con/react-theme';
import { Button, DialogTitle, MessageBox, makeStyles } from '@akelius-con/react-ui-kit-components';
import { AkEdit } from '@akelius-con/react-ui-kit-icons';
import { Box, Dialog, DialogActions, DialogContent, IconButton, Tooltip, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import { cacheKeys } from 'modules/order/constants';
import updateOrderSupplier, { UpdateSupplierParam } from 'modules/order/graphql/mutations/updateOrderSupplier';
import { IOrderResponse } from 'modules/order/graphql/queries/useGetOrder';
import { countryCodeMap } from 'modules/order/utils/countryCodeMap';
import { useGetUser } from 'modules/order/utils/useGetUser';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LinkToSupplier } from 'shared/components/LinkToSupplier';
import { snackbar } from 'shared/components/Snackbar';
import SuppliersDropdown, { getSupplierOptionFromSupplier } from 'shared/components/SuppliersDropdown';
import { Supplier } from 'shared/graphql/queries/getSuppliers';

const useStyles = makeStyles()(() => ({
    cardWrapper: {
        marginTop: '24px',
        height: 'calc(100% - 24px)',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        marginBottom: '16px',
    },
    supplierAddress: {
        marginTop: '16px',
    },
    loadingIndicator: {
        position: 'absolute',
        top: 0,
        height: '100%',
        width: '100%',
        background: 'rgb(255 255 255 / 80%)',
    },
    supplierName: {
        color: colors.black,
    },
    editButton: { margin: 0 },
    supplierBox: {
        position: 'relative',
        boxSizing: 'border-box',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${colors.lineGrey}`,
        padding: `24px 16px`,
        transition: 'border-color 200ms',
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    disabledIcon: {
        color: `${colors.disabledGrey} !important`,
    },
}));

interface Props {
    supplier: Supplier;
    readonly?: boolean;
}

const SupplierCard: FC<Props> = props => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const { supplier, readonly } = props;
    const params = useParams<{ orderId: string }>();
    const orderId = params.orderId as string;
    const queryClient = useQueryClient();
    const { getToken } = useGetUser();
    const [openSupplierChangeDialog, setOpenSupplierChangeDialog] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { addressDetails, address: supplierAddress } = supplier;
    const { city, country } = addressDetails;
    const [updatedSupplier, setUpdatedSupplier] = useState<Supplier | null>(supplier);

    const performOpenSupplierChangeDialog = useCallback(() => {
        setOpenSupplierChangeDialog(true);
    }, [setOpenSupplierChangeDialog]);

    const closeSupplierChangeDialog = useCallback(() => {
        setOpenSupplierChangeDialog(false);
    }, [setOpenSupplierChangeDialog]);

    const onCloseDialogHandler = (_event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason !== 'backdropClick') {
            closeSupplierChangeDialog();
        }
    };

    const updateOrderSupplierMutation = useMutation(updateOrderSupplier(), {
        onSuccess: res => {
            setTimeout(() => {
                queryClient.setQueryData<IOrderResponse>(cacheKeys.order(orderId), data => {
                    if (!data) {
                        return;
                    }

                    return {
                        order: {
                            ...data.order,
                            supplier: res.updateOrderSupplier.supplier,
                        },
                    };
                });

                snackbar.success(t('common.auto-save.success-msg'));

                setIsLoading(false);
                closeSupplierChangeDialog();
            }, 50);
        },
        onError: () => {
            snackbar.error(t('common.auto-save.error-msg'));
            setIsLoading(false);
        },
    });

    const onSupplierChange = useCallback((supplier: Supplier | null) => {
        setUpdatedSupplier(supplier);
    }, []);

    const submitChanges = () => {
        if (updatedSupplier) {
            setIsLoading(true);
            const queryVars: UpdateSupplierParam = {
                getToken,
                orderId,
                input: {
                    supplierId: updatedSupplier.id,
                },
            };
            updateOrderSupplierMutation.mutate(queryVars);
        }
    };

    return (
        <div className={classes.cardWrapper}>
            <Typography className={classes.title} variant="h2">
                {`${t('purchase-order.general-info.supplier')}*`}
            </Typography>
            <div className={classes.supplierBox}>
                <LinkToSupplier supplier={supplier} variant="h3" className={classes.supplierName} />
                <Typography variant="body1" className={classes.supplierAddress}>
                    {supplierAddress}
                </Typography>
                <Typography variant="body1">{city}</Typography>
                <Typography variant="body1">{countryCodeMap[country]}</Typography>

                <Box flexGrow={1} />

                <div className={classes.actionContainer}>
                    <Tooltip title={!readonly ? t('purchase-order.add-carrier-of-cost.change-supplier') : ''} placement="right-start">
                        <IconButton
                            size="medium"
                            data-testid="change-supplier"
                            className={classes.editButton}
                            disabled={readonly}
                            onClick={performOpenSupplierChangeDialog}
                        >
                            <AkEdit className={cn({ [classes.disabledIcon]: readonly })} />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            <Dialog disableEscapeKeyDown maxWidth="sm" fullWidth open={openSupplierChangeDialog} onClose={onCloseDialogHandler}>
                <DialogTitle onClose={closeSupplierChangeDialog} title={t('purchase-order.general-info.change-supplier-modal-title')} />
                <DialogContent>
                    <MessageBox type="warning">
                        <Typography variant="body1">{t('purchase-order.general-info.change-supplier-warning')}</Typography>
                    </MessageBox>

                    <Box marginTop="20px" />

                    <SuppliersDropdown
                        data-testid="supplier-selection"
                        disabled={isLoading}
                        label={t('purchase-order.supplier')}
                        value={getSupplierOptionFromSupplier(updatedSupplier)}
                        onChange={onSupplierChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" label={t('common.cancel')} onClick={closeSupplierChangeDialog} data-testid="cancel" />
                    <Button
                        isLoading={isLoading}
                        disabled={!updatedSupplier || isLoading}
                        variant="contained"
                        data-testid="change-supplier-button"
                        label={t ? t('common.action.change') : 'change'}
                        onClick={submitChanges}
                        color="primary"
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SupplierCard;
